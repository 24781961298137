// eslint-disable-next-line no-redeclare,no-unused-vars
function buildHelp(row) {
  if (help) {
    if (row) help.removeClass('d-none').open(row);
    else help.toggleClass('d-none');
    return;
  }

  help = $('<div id="help" class="view-small"></div>').appendTo('main');
  var contCard = $('<div class="card"></div>').appendTo(help);
  var contBody = $('<div class="card-body mh-100"></div>').appendTo(contCard);
  help.ident = 'help';

  // resize
  var width = localStorage.getItem('helpWidth');
  if (width) help.width(width);
  help.resizable({
    handles: 'w',
    stop: function (evt, ui) {
      localStorage.setItem('helpWidth', ui.size.width);
    },
  });

  // close button
  $(
    '<span class="position-absolute top-0 end-0 p-3 link"><i class="icon fa-regular fa-times"></i></span>'
  )
    .appendTo(contBody)
    .on(click, function () {
      help.addClass('d-none');
    });

  // support
  $('<h2 class="text-center">' + info.support.label + '</h2>').appendTo(
    contBody
  );
  var contSupport = $('<ul class="list-group list-group-flush"></ul>').appendTo(
    contBody
  );

  // portal support
  $(
    '<li class="list-group-item">Via Serviceportal: <a href="https://' +
      info.support.portal +
      '" target="_blank">' +
      info.support.portal +
      '</a></li>'
  ).appendTo(contSupport);

  // mail support
  $('<span class="link">' + info.support.mail + '</span>')
    .appendTo(contSupport)
    .on(click, function () {
      mailer({to: info.support.mail});
    })
    .wrap('<li class="list-group-item">Via E-Mail: </li>');

  // videocall support
  if (store.supportVideocall)
    $(
      '<li class="list-group-item">Via Teams: <a href="' +
        store.supportVideocall +
        '" target="_blank">' +
        info.support.videocall +
        '</a></li>'
    ).appendTo(contSupport);

  // if (store.supportTelegram)
  //   $(
  //     '<li class="list-group-item">Via Telegram: <a href="tg://join?invite=' +
  //       store.supportTelegram +
  //       '">' +
  //       info.support.telegram +
  //       '</a></li>'
  //   )
  //     .appendTo(contSupport)
  //     .on(click, function () {
  //       noLogoutInfo = true;
  //     });
  // $(
  //   '<li class="list-group-item">Via Telefon: <a href="tel:' +
  //     info.support.phone +
  //     '">' +
  //     info.support.phone +
  //     '</a></li>'
  // ).appendTo(contSupport);

  // wiki
  contSupport.append('<hr></hr>');
  var backBtn = $(
    '<span class="position-absolute start-0 p-2 link invisible"><i class="icon fa-regular fa-arrow-left"></i></span>'
  )
    .appendTo(contBody)
    .on(click, function () {
      help.list.removeClass('d-none');
      help.detail.addClass('d-none');
      backBtn.addClass('invisible');
      if (!help.list.table.created) help.list.table.loadData();
    });
  $('<h2 class="text-center">' + parameter.wiki.label + '</h2>').appendTo(
    contBody
  );

  // table
  var contTable = $('<div></div>').appendTo(contBody);
  help.list = new buildTable(contTable, {
    object: 'wiki',
    objectSub: 'main',
    visible: true,
    noHeader: true,
    addSearch: true,
    noSelect: true,
    view: help,
    detailCallback: function (row) {
      help.open(row);
    },
  });

  // build detail
  help.detail = $(
    '<div id="help-detail" class="d-none" data-mdb-perfect-scrollbar="true"></div>'
  ).appendTo(contBody);

  // open detail
  help.open = function (row) {
    backBtn.removeClass('invisible');
    help.list.addClass('d-none');
    help.detail.empty().removeClass('d-none');
    if (row.title) help.detail.append('<h3>' + row.title + '</h3>');
    if (row.instruction) help.detail.append(row.instruction);
    if (row.screenshot)
      $.each(row.screenshot, function (i, docId) {
        buildField(
          help.detail,
          {
            value: docId,
            object: 'wiki',
          },
          'screenshot',
          {
            field: 'file',
            reference: 'document',
          }
        );
      });

    var lightboxObj = new mdb.Lightbox(help.detail[0]);
    help.detail.find('img').each(function (i) {
      $(this)
        .addClass('link')
        .addClass('wiki-preview')
        .on(click, function () {
          lightboxObj.open(i);
        });
    });
  };
  new mdb.PerfectScrollbar(help.detail[0]);

  // load data
  if (row) help.open(row);
  else help.list.table.loadData();
}
